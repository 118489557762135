<template>
  <div>
    <el-card>
      <nav class="input" style="width: auto">
        <el-input v-model="search.keyword" size="medium" placeholder="标题" />
      </nav>
      <nav class="input" style="width: auto">
        <el-select size="medium" v-model="search.status">
          <el-option value="-1" label="全部"></el-option>
          <el-option
            :value="index"
            :label="item"
            v-for="(item, index) in auditState"
            :key="index"
          ></el-option>
        </el-select>
      </nav>
      <nav class="input" style="width: 150px">
        <el-button size="small" @click="reset">重置</el-button>
        <el-button type="primary" size="small" @click="subimt">{{
          '搜索'
        }}</el-button>
      </nav>
      <!-- <nav class="input" style="width: auto">
        <el-button type="primary" size="small"  @click="add">{{
          '添加商品'
        }}</el-button>
      </nav> -->
    </el-card>

    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="企业名称" prop="enterpriseName">
        </el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="封面" prop="picture">
          <template slot-scope="{ row }">
            <p-image :src="row.picture" auto></p-image>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="title">
          <template slot-scope="{ row }">
            <el-tag :type="row.status==1?'success':'warning'"
              >
              {{auditState[row.status]}}
            </el-tag>
            <p v-if="row.remark">审核不通过原因:{{ row.remark }}</p>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              @click="audit(row)"
              >审核通过</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              style="color:red"
              @click="audit_f(row)"
              >审核不通过</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              style="color:tan"
              @click="checkTheDetails(row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="search.page"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="search.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <Drawers ref="drawer" title="查看详情"></Drawers>
    <failing
      :dialogVisible.sync="p_a"
      :form.sync="p_a_f"
      @getlist="getlist"
    ></failing>
  </div>
</template>

<script>
import list from '/src/mixin/list.js'
import { auditState } from './enterprise-data'
export default {
  mixins: [list],
  components: {
    failing: () => import('./enterprise_article_check_list/failing.vue')
  },
  data() {
    return {
      search: {
        keyword: '',
        page: 1,
        pageSize: 10,
        status:'0'
      },
      auditState,
      p_a: false,
      p_a_f: {}
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async checkTheDetails(row) {
      const { data: res } = await this.$http.get(
        '/admin/Enterprise/getEnterpriseArticleCheckDetail',
        {
          params: {
            id: row.id
          }
        }
      )
      this.$refs.drawer.init(8)
      this.$refs.drawer.ruleForm = res.data
    },
    async audit(row) {
      const { data: res } = await this.$http.post(
        '/admin/Enterprise/enterpriseArticleCheck',
        {
          id: row.id,
          status: 1
        }
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlist()
      }
    },
    audit_f(row) {
      this.p_a = true
      this.p_a_f = row
      // const {data:res} =  await this.$http.post('/admin/Enterprise/cert',{
      //        id:row.id,
      //        status:2
      //    })
    },
    async getlist() {
      const { data } = await this.$http.get(
        '/admin/Enterprise/getEnterpriseArticleCheckList',
        {
          params: {
            ...this.search
          }
        }
      )
      this.list = data.data.list
      this.total = data.data.totalCount
    },
    subimt() {
      this.getlist()
    }
  }
}
</script>

<style lang="less" scoped></style>
